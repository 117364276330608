.use_institution_container {
  width: 100vw;
  height: 100vh;
  position: relative;

  &__title {
    font-size: 34px;
    font-weight: 600;
    color: #333333;
    margin-top: 197px;
  }

  &__content {
    width: 560px;
    height: 330px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px 0px rgba(46, 94, 242, 0.16);
    border-radius: 14px;
    box-sizing: border-box;
    padding: 36px 80px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }

    &__list::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &__list {
      flex: 1;
      margin-top: 30px;
      width: 100%;
      overflow: scroll;

      &__item {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        text-shadow: 0px 0px 50px rgba(46, 94, 242, 0.16);
        overflow: hidden;
        margin-bottom: 24px;
        transition: all 0.2s linear;
      }

      &__item-active {
        color: #FFFFFF;
        background: #2E5EF2;
      }

      &__item-normal {
        color: #2E5EF2;
        background: #EAEEFD;
      }
    }
  }
}

.use_institution_container>img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}
@root-entry-name: default;