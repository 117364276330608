.tree-menu-context-container {
  // background-color: red;
  user-select: none;
}

.context-list {
  padding: 4px;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.context-children-list {
  padding: 4px;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  position: absolute;
  left: calc(~'100% + 6px');
  top: -4px;
  transform: translate(-2000px);
}

.context-children-list-show {
  transform: translate(0);
}

.context-item {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 4px;
  position: relative;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.vsf-treemenu-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.vsf-treemenu-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.vsf-treemenu-search {
  margin-bottom: 6px;
  width: 100%;
  flex: 0;
}

.vsf-treemenu-content {
  flex: 1;
  width: 100%;
}
@root-entry-name: default;