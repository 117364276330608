.ant-pagination-options {
  display: none !important;
}

.ant-pro-table .vs-table-addtion {
  min-width: 80px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;

  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.ant-pro-table .ant-pro-table-search {
  margin-bottom: 24px !important;
}

.ant-pro-table .ant-form-item .ant-row {
  align-items: center;
}

.department_account_list .ant-input-group-addon {
  display: none;
}

.department_account_list
  .ant-form-item
  .ant-form-item-control
  .ant-input-affix-wrapper {
  width: 253px !important;
}

.department_account_list
  .ant-form-item
  .ant-form-item-control
  .ant-select-selector {
  width: 253px !important;
}

.department_account_list .vs-table-addtion {
  background-color: white;
  color: rgb(46, 94, 242);
  border-radius: 6px;
}

.ant-pro-table .ant-form-item .ant-form-item-control .ant-input-affix-wrapper {
  width: 110px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;
}

.ant-pro-table .ant-form-item .ant-form-item-control .ant-select-selector {
  width: 110px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;
}

.ant-pro-table
  .ant-form-item
  .ant-form-item-control
  .ant-select-selector
  .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-pro-table .ant-table-thead .ant-table-cell {
  background: #f6f6f6;
}

.antd-picker-width .ant-picker {
  width: 100%;
}

.ant-pagination-item-active {
  background: #eaeefd !important;
}

.ant-modal-footer {
  button:nth-child(1) {
    background-color: #e6ebfc;
    color: #0756eb;
  }
}

.ant-picker {
  width: 100%;
}

.antd-formitem-contnent-border .ant-form-item-control textarea {
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.custom_ant_upload .ant-upload {
  min-height: auto !important;
}

.custom_ant_upload .ant-upload-list-item-name {
  display: none !important;
}

.custom_ant_upload .ant-upload-list-picture {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

// .custom_ant_upload .ant-upload-list-picture-container {
//   width: 84px !important;
//   height: 84px !important;
//   padding: 0 !important;
//   margin-right: 15px;
//   margin-bottom: 14px;
// }

// .custom_ant_upload .ant-upload-list-item-error,
// .custom_ant_upload .ant-upload-list-picture-card,
// .custom_ant_upload .ant-upload-list-item-error,
// .custom_ant_upload .ant-upload-list-item-info,
// .custom_ant_upload .ant-upload-list-item-thumbnail,
// .custom_ant_upload .ant-upload-span,
// .custom_ant_upload .ant-upload-list-item-image,
// .custom_ant_upload .ant-upload-list-item {
//   width: 84px !important;
//   height: 84px !important;
//   padding: 0 !important;
//   position: relative;
// }

.ant-upload-list-item-card-actions {
  // display: none;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

// ant-upload-list ant-upload-list-picture

.ant-input-number,
.ant-input-number-in-form-item,
.ant-input-number {
  width: 100% !important;
}

// .ant-select {
//   width: 100% !important;
// }

.custom_range_digit {
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
}

.custom_range_digit .ant-input-number {
  width: 222px !important;
}

.vs-pageContainer,
.vs-pageContent {
  min-width: 100%;
  min-height: 100%;
}

.ggnrzy {
  margin-top: 15px;
}

.ant-popover-content {
  width: 220px;
  height: 113px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #eeeeee;
}

.ant-popover-inner {
  width: 100%;
  height: 100%;
}

.ant-popover-inner-content {
  width: 100% !important;
  height: 100%;
}

.ant-popover-message {
  width: 100%;
}

.ant-popover-message-title {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-top: 8px;
}

.ant-popover-buttons {
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

.ant-popover-buttons .ant-btn-default {
  width: 80px;
  height: 34px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
}

.ant-popover-buttons .ant-btn-primary {
  width: 80px;
  height: 34px;
  background: #2e5ef2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.ant-pro-table-search-form .ant-btn-primary {
  width: 64px;
  height: 36px;
  background: #eaeefd;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
}

.custom_config_table .ant-space-item span {
  // color: red;
  opacity: 0.5;
}

.monitor__container .ant-pro-table {
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
}

.ant-table-cell {
  position: relative;
}

.custom_table_cell_background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sample_config_form_view::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.man_th {
  background-color: #f7f8fd !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.woman_th {
  background-color: #fdf8f7 !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.monitorStatus {
  .ant-pro-table .ant-form-item .ant-form-item-control .ant-select-selector {
    width: 140px;
  }
}

@root-entry-name: default;