@import '~@/style/vars.less';

.gpuHAW,
.dyZJBf {
  padding: 0 !important;
}

.vs-pageContainer {
  border-radius: 6px;
}

.actionSpan {
  color: black;
}

.vsf-layout-sec-main main {
  padding-left: @padding !important;
  flex: 1;
  display: flex;
}

.vsf-layout-main main {
  flex: 1;
}

// .monitor__container {
//   display: flex;
//   flex-direction: column;
// }

.vsf-layout-main main .vs-pageContainer,
.vsf-layout-sec-main main .vs-pageContainer,
.vsf-layout-sec-main .monitor__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0;
}

.ant-pro-table .custom_config_table-extra {
  flex: none !important;
}

.vsf-layout-main main .vs-pageContainer .vs-pageContent {
  flex: 1;
  height: 0;
  overflow-y: scroll;
}

.vsf-layout-sec-main .monitor__container .vs-section,
.vsf-layout-main main .vs-pageContainer .vs-section,
.vsf-layout-sec-main main .vs-pageContainer .vs-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vsf-layout-sec-main .monitor__container .vs-section .vs-section-body,
.vsf-layout-main main .vs-pageContainer .vs-section .vs-section-body,
.vsf-layout-sec-main main .vs-pageContainer .vs-section .vs-section-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container,
.vsf-layout-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container,
.vsf-layout-sec-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table,
.vsf-layout-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table,
.vsf-layout-sec-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper,
.vsf-layout-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper,
.vsf-layout-sec-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper {
  flex: 1;
  display: flex;
}

.vsf-layout-main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading,
.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading,
.vsf-layout-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading,
.vsf-layout-sec-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading {
  width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vsf-layout-main .ant-spin-container,
.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container,
.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container,
.vsf-layout-sec-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vsf-layout-main .ant-spin-container .ant-table,
.vsf-layout-sec-main
  .monitor__container
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table,
.vsf-layout-sec-main
  main
  .vs-pageContainer
  .vs-section
  .vs-section-body
  .vs-base-table-container
  .ant-pro-table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table {
  flex: 1;
}

.@{layout} {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f6f6f6;

  .@{layout}-sider {
    margin-top: @margin-1;
    box-shadow: 1px 1px 5px #d9d9d9;
  }

  .@{layout}-header {
    width: 100%;
    height: 64px;
    min-height: 64px;
    text-indent: 20px;
    // border-bottom: 1px solid #d9d9d9;
    box-shadow: 1px 1px 5px #d9d9d9;
    box-sizing: border-box;
    // padding: 0 18%;
    position: sticky;

    &-tabs {
      height: 64px;

      &-item {
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-right: 30px;
        transition: all 0.2s linear;
        text-indent: 0;
        position: relative;

        &-line {
          width: 50px;
          height: 2px;
          background: #2e5ef2;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }

      &-item-active {
        font-weight: bold;
        color: #333333;
      }

      &-item-normal {
        color: #666666;
      }
    }

    &-select {
      width: 180px;
      height: 36px;
      background: #fafafa !important;
      // border: 1px solid #EEEEEE;
    }

    &-select .ant-select-selector {
      border-radius: 6px;
      text-indent: 0;
    }

    &-drop {
      margin-left: 20px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;

      &-icon {
        width: 14px;
        height: 14px;
        margin-left: 8px;
      }

      &-name {
        text-indent: 0;
        margin-left: 10px;
      }
    }
  }

  .@{layout}-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .@{layout}-main {
    // flex: 1;
    // padding: @padding;
    width: 1200px;
    // margin-top: @margin-1;
    overflow-y: auto;
    padding-top: @padding;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
  }

  .@{layout}-sec-main {
    width: 0;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    // padding: @padding;
  }
}

.@{layout}-breadcrumb {
  width: 100%;
  display: flex;
  margin-top: -9px;
  margin-bottom: 15px;

  li {
    display: block;
  }
}

.@{layout}-sec {
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: row;
  flex: 1;

  .@{layout}-sec-sider::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .@{layout}-sec-sider {
    // height: 920px;
    background: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 0 16px;
    // margin-top: @padding;
    overflow: scroll;

    &-header {
      width: 100%;
      height: 55px;
      border-bottom: 1px solid #eeeeee;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      box-sizing: border-box;
      padding-left: 10px;
    }

    &-header > img {
      width: 22px;
      height: 22px;
      margin-right: 9px;
    }

    &-new {
      width: 208px;
      height: 36px;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 0 10px;
      overflow: hidden;
      position: relative;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &-new > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ff3d11;
      opacity: 0.06;
    }

    &-new > span {
      font-size: 14px;
      font-weight: bold;
      color: #ff3d11;
    }

    &-new > img {
      width: 16px;
      height: 16px;
    }
  }
}

.ant-layout-sider-trigger {
  display: none;
}

.vs-table-addtion {
  border-radius: 6px;
}

.orgName {
  font-size: 16px;
  color: #666666;
}

@root-entry-name: default;