.custom_upload_btn {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;

  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  cursor: pointer;

  & > img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

@root-entry-name: default;