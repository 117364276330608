@import '~@/style/vars.less';

// 滚动条整体宽度
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

// // 滚动条滑槽样式
::-webkit-scrollbar-track {
  background: #f6f6f6;
}

// // 滚动条样式
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #dddddd;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

ul,
ol,
dl {
  list-style: none;
}

.aic {
  display: flex;
  align-items: center;
}

.jcc {
  display: flex;
  justify-content: center;
}

.jcsa {
  display: flex;
  justify-content: space-around;
}

.jcsb {
  display: flex;
  justify-content: space-between;
}

.scroll {
  overflow-y: scroll;
}

.col {
  display: flex;
  flex-direction: column;
}

.jcfe {
  display: flex;
  justify-content: flex-end;
}

.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@root-entry-name: default;