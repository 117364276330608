@import "~@/style/vars.less";

.@{layout}-sec-sider .ant-tree-title {
  display: inline-block;
  width: 100%;
}

.@{layout}-sec-sider .ant-tree .ant-tree-node-content-wrapper {
  width: 77%;
}

.@{layout}-sec-sider .ant-tree-treenode {
  width: 100%;
  // flex: 1;
}


.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  height: 36px;
  background: #EAEEFD;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #2E5EF2;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
}

.treenode-item-normal {
  width: 100%;
  height: 36px;
  // background: #F5F5F5;
  border-radius: 6px;
  transition: all 0.2s linear;
  box-sizing: border-box;
  padding: 0 8px;

  &-iconAdd {
    width: 16px;
    height: 16px;
    background-image: url('../assets/images/icon/tree_add_gray_icon.png');
    background-size: 16px 16px;
    margin-right: 6px;
  }

  &-iconMore {
    width: 16px;
    height: 16px;
    background-image: url('../assets/images/icon/tree_more_gray_icon.png');
    background-size: 16px 16px;
  }

}

.treenode-item-normal:hover {
  height: 36px;
  background: #EAEEFD;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #2E5EF2;
}

.treenode-item-normal:hover .treenode-item-normal-iconAdd {
  background-image: url('../assets/images/icon/tree_add_blue_icon.png');
}

.treenode-item-normal:hover .treenode-item-normal-iconMore {
  background-image: url('../assets/images/icon/tree_more_blue_icon.png');
}

.ant-tree-switcher_close,
.ant-tree-switcher_open {
  display: flex;
  align-items: center;
}
@root-entry-name: default;